export default {
  category: 'book',
  name: "It Didn't Start With You",
  author: 'Mark Wolynn',
  date: '12/25/24',
  path: '/notes/it-didnt-start-with-you',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-it-didnt-start-with-you-mark-wolynn.jpg',
  tags: ['Trauma', 'Self-help', 'Psychology'],
  time: 29,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Didnt-Start-You-Inherited-Family/dp/1101980389/ref=sr_1_1?dib=eyJ2IjoiMSJ9.Mn2-JXk4mzPKxHJhIS98veGMl1PXYuJyADQcpQpMwwXw-hWsFvGQ-ZNbEPmqzmkzvbzfMYIGf1TiIXBcOTjx28HdhGIP2LbFgSSJ9m1_fpyTNq-4w2xLuRPq9gtxMqNI904eBxVVjRAoqZ2t2dhI6ysqEoCqvdBRl6yZ_TGR_pQHhhxoXL2ro5XgJVdYFmZCvn5IFEX9HKnhibwgBmYtISD-7IvzW9CuuKdMYtTAgMY.RhY3mygkNuAdya5SunYe6gniiAluemZGN0YDtzcHPFs&dib_tag=se&hvadid=694593118947&hvdev=c&hvexpln=67&hvlocphy=9061189&hvnetw=g&hvocijid=14573799646918193569--&hvqmt=e&hvrand=14573799646918193569&hvtargid=kwd-298544284005&hydadcr=15524_13517408&keywords=it+didn%27t+start+with+you&qid=1735155709&sr=8-1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/673f42bafdff953a8e3fc900',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
