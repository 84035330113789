export default {
  category: 'book',
  name: 'Quran',
  author: 'Talal Itani',
  date: '2/9/25',
  path: '/notes/quran',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-quran-talal-itani.jpg',
  tags: ['Religion', 'Spirituality', 'Islam'],
  time: 117,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Quran-English-Translation-Clear-Modern/dp/0986136808',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/676c6456eb6e6007a569299c',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
