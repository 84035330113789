export default {
  category: 'book',
  name: 'Mere Christianity',
  author: 'C S Lewis',
  date: '12/28/24',
  path: '/notes/mere-christianity',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-mere-christianity-c-s-lewis.jpg',
  tags: ['Religion', 'Christianity'],
  time: 37,
  rating: 8.5,
  review: '',
  link: 'https://www.amazon.com/Mere-Christianity-C-S-Lewis-ebook/dp/B0CNT929FK/ref=sr_1_1?dib=eyJ2IjoiMSJ9.-G6ZecRRDWQpbkluykqBw_oMjQmLv0hi2LPV7lGHAleYRfGV7a-po7RBRHSoDhq7Ta8GhBiISHSoRUnpZ1x_ASGslrUIsW6EC0oMWcsve5eBsc4hKWxCPb3VgsA1m_5QSncjnQiz__Ue1E96l4qjOpwBhfthTFEwjqf06y9-tqrcTJpYg2Wime_UpsHad7g0JcIBbA-N-0OtEYjC9Q1vGzGdGqqdbchQcUKmbEjS7iY.MeWVkQ5ahfe4V_s9yE5ZGg7EBNXAwVv3uwt4f1XC-8w&dib_tag=se&hvadid=598727083136&hvdev=c&hvlocphy=9012094&hvnetw=g&hvqmt=e&hvrand=11104957124666338353&hvtargid=kwd-10982865297&hydadcr=8238_13544377&keywords=%27mere+christianity%27&qid=1735402063&s=books&sr=1-1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/675a401d26c80f2841497655',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
