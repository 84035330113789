export default {
  category: 'book',
  name: "Don't Lose Your Mind, Lose Your Weight",
  author: 'Rujuta Diwekar',
  date: '6/28/24',
  path: '/notes/dont-lose-your-mind-lose-your-weight',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-dont-lose-your-mind-lose-your-weight-rujuta-diwekar.jpg',
  tags: ['Health', 'Diet', 'Self-help'],
  time: 34,
  rating: 7.4,
  review: '',
  link: 'https://www.amazon.com/Dont-Lose-Your-Mind-Weight/dp/8184001053',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/666ed50d9591b381dacd97fd',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
