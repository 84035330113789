export default {
  category: 'book',
  name: 'Ishmael',
  author: 'Daniel Quinn',
  date: '1/19/25',
  path: '/notes/ishmael',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-ishmael-daniel-quinn.jpg',
  tags: ['Fiction', 'Psychology'],
  time: 22,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Ishmael-Novel-Daniel-Quinn/dp/0553375407',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/677f0e7cb12a4104beed6429',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
