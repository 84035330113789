export default {
  category: 'book',
  name: 'The Artists Way',
  author: 'Julia Cameron',
  date: '12/23/24',
  path: '/notes/the-artists-way',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-artists-way-julia-cameron.jpg',
  tags: ['Art', 'Self-help', 'Creativity', 'Writing'],
  time: 93,
  rating: 8.9,
  review: '',
  link: 'https://www.amazon.com/Artists-Way-25th-Anniversary/dp/0143129252',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/66fbedb84189d77e0abbb3c0',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
