export default {
  category: 'book',
  name: 'Unreasonable Hospitality',
  author: 'Will Guidara',
  date: '2/24/25',
  path: '/notes/unreasonable-hospitality',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-unreasonable-hospitality-will-guidara.jpg',
  tags: ['Customer Service', 'Biography', 'Leadership', 'Entrepreneurship'],
  time: 14,
  rating: 6.5,
  review: '',
  link: 'https://www.amazon.com/Unreasonable-Hospitality-Remarkable-Giving-People/dp/0593418573',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/678cf16da05cebf3ccf724b8',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
