export default {
  category: 'book',
  name: 'Fundraising',
  author: 'Ryan Breslow',
  date: '3/14/25',
  path: '/notes/fundraising',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-fundraising-ryan-breslow.jpg',
  tags: ['Fundraising', 'Money', 'Business', 'Leadership', 'Entrepreneurship'],
  time: 8,
  rating: 7.5,
  review: '',
  link: 'https://www.amazon.com/Fundraising-Ryan-Breslow/dp/B09CR7TFDT',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.TwosApp.com/67c104561ede7739bf8b3166',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
