export default {
  category: 'book',
  name: 'The Holy Bible',
  author: 'Thomas Nelson',
  date: '12/7/24',
  path: '/notes/the-holy-bible',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-the-holy-bible-thomas-nelson.jpg',
  tags: ['Religion', 'Christianity'],
  time: 179,
  rating: 8.8,
  review: '',
  link: 'https://www.amazon.com/Bible-Large-Hardcover-Letter-Comfort/dp/0718096797/ref=sr_1_2_sspa?dib=eyJ2IjoiMSJ9.ljRQ3cINAwFxO-IqxoblCisrKd47yqRx77zx0wLi-sKY6wBmXqerDWlz__wdI759O1afUQwbmPZgAikEbRIhDYhjF2QCRoy6otCD8hGewUEBvWcfKEvVAqS5-ox3BIsp96OrXx_nHSWpS5tCc8p5OGGmT7Pc3oXyptXDi1WRjnPiMFJAH8foxu5o8lPHPMCJZ3KhGK3bfUk6qhGWFAWy98R-wOjGT0W8zoqGwMtF4UY.84GOAjYT5kh6bRtU6GFeUruKLnRK6x7JuSJTxiIz4YA&dib_tag=se&keywords=the+bible&qid=1733610999&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&psc=1',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/65d2b25edd2453398d78bec0',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
